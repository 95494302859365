// Please keep in alphabetical order, can keep 'other' in bottom
export enum ELogActionType {
  A3 = 'A3',
  ADMIN = 'Admin',
  APPLICANT = 'Applicant',
  COMMANDER = 'Commander',
  COE_TEAM = 'COE Team',
  FUNDING = 'Funding',
  GAINS = 'Gains',
  GTC = 'GTC',
  INPROCESSING = 'Inprocessing',
  LNO = 'LNO',
  MEDICAL = 'Medical',
  MILPAY = 'Milpay',
  ORDERS = 'Orders',
  OUTPRO = 'Out-Processing',
  PERSONNEL = 'Personnel',
  PIPELINES = 'Pipeline',
  PRE_INPROCESSING = 'Pre-Inprocessing',
  RECRUITER = 'Recruiter',
  RECRUITING = 'Recruiting',
  SARM = 'SARM',
  SECURITY = 'Security',
  SPONSORING_UNIT = 'Sponsoring Unit',
  TRAVEL_PAY = 'Travel Pay',
  UFT_TEAM = 'UFT Team',
  OTHER = 'Other'
}

export enum Actions {
  GAINS,
  INPROCESSING,
  OTS,
  IMPORT_DCR,
  IMPORT_TLN,
  OTS_CLASSES,
  INPRO_CLASSES,
  START_PAY,
  ORDERS,
  SPONSORING_UNITS,
  VIEW_TLN,
  VIEW_DCR,
  CONFIGURE_DASHBOARD
}
