var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass:
        "overflow-hidden border border-gray-300 rounded-full w-14 h-14",
    },
    [
      !_vm.photoUrl
        ? _c("icon-component", {
            staticClass: "bg-gray-200 w-full h-full",
            attrs: { name: "user", height: 14, width: 14, fill: "black" },
          })
        : _c("img", {
            attrs: { src: _vm.photoUrl, alt: "Profile Image" },
            on: { error: _vm.imgError },
          }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }