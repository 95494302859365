
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import IconComponent from '@/components/IconComponent.vue';

@Component<ProfilePhotoComponent>({ components: { IconComponent } })
export default class ProfilePhotoComponent extends Vue {
  @Prop()
  readonly photoLocation?: string;

  photoUrl = '';

  @Watch('photoLocation')
  watchPhotoUrl(): void {
    this.setPhotoUrl();
  }

  private async setPhotoUrl(): Promise<void> {
    if (this.photoLocation) {
      this.photoUrl = await this.$store.dispatch(
        'storageModule/getObjectUrl',
        this.photoLocation
      );
    } else {
      this.photoUrl = '';
    }
  }

  // if image load fails
  imgError(): void {
    this.photoUrl = '';
  }

  mounted(): void {
    if (this.photoLocation) {
      this.setPhotoUrl();
    }
  }
}
