/* eslint-disable @typescript-eslint/no-explicit-any */
import { IconProps } from '@/assets/icons';

export class ActionMenuItem {
  displayText: string;
  value: any;
  count?: number;
  showCount = false;
  icon?: IconProps;
  hasSeparator = false;
  action: () => void;

  constructor(input: {
    displayText: string;
    value: any;
    action: () => void;
    icon?: IconProps;
    hasSeparator?: boolean;
    count?: number;
    showCount?: boolean;
  }) {
    this.displayText = input.displayText;
    this.value = input.value;
    this.action = input.action;
    this.count = input.count;
    this.showCount = input.showCount ?? false;
    this.icon = input.icon;
    this.hasSeparator = input.hasSeparator ?? false;
  }
}
